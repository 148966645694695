<template>
  <div id="inside" v-if="isLoggedIn">
    <div class="container">
      <div class="row">
        <div
          class="
            col-12
            d-flex
            align-items-start
            justify-content-start
            flex-column
            col-lg-12
            px-2
            pb-4
          "
        >
          <div class="btn-group btn-group-sm">
            <a
              href="/"
              class="btn btn-link d-flex flex-column justify-content-center"
              ><i class="fa fa-home fa-fw fa-lg"></i
            ></a>
            <a href="#" class="btn btn-link" contenteditable="true"
              ></a
            >
          </div>
        </div>
        <div class="col-lg-5">
          <h5>Olá, {{ user.FullName }}.</h5>
          <p v-if="docDebtor.length > 0 && !loading">Verifique seus documentos.</p>
        </div>
        <div class="col-lg-7">
        </div>
      </div>
      <ProgressLoading v-bind:loading="loading" />
    </div>
    <div class="container mt-5" v-if="docDebtor.length < 1 && !loading">
      Não há documentos disponíveis.
    </div>
    <div class="container" v-if="this.docDebtor.ProofFile != null && !loading">


      <div class="row">
        <div
          class="
            d-inline-flex
            flex-wrap flex-column
            col-md-12 col-acao col-lg-12
          "
        >
        
        <div class="card" v-if="!loading">
          <div class="card-header" style="">
            <i class="fa fa-fw mr-2 text-secondary fa-edit"></i>Propostas
          </div>
        <div class="card-body">
          <div class="row">
            <embed v-if="this.docDebtor.ProofFile != null"  
                    v-bind:src="documentURL" 
                    type="application/pdf" width="100%">      

          </div>
          <div class="table-responsive mb-3" v-if="!loading">
                    <table class="table table-sm" width="100%" cellspacing="0">
                      <thead class=""></thead>
                      <tbody class="text-muted">
                        <tr class="">
                          <!-- <td class="align-items-center d-flex">
                            <i class="fa fa-file-text fa-2x mr-2 my-2"></i>
                            <p class="small mb-1">
                              {{ formatDocument(user.DocNumber) }}
                            </p>
                          </td> -->
                          <td class="">
                            
                            <!-- <a class="btn btn-link btn-sm mt-3 ml-2" href="#"
                             @click="viewDocument()"><i class="fa fa-eye fa-fw"></i
                              >&nbsp;Visualizar&nbsp;</a
                            > -->
                            
                            
                            <a id="btn-approve" class="btn btn-lg btn-secondary" href="#" v-if="this.docDebtor.IsApproveByDebtor == null"
                              @click="approveOrRefuseDocument(1)"><i class="fa fa-check fa-fw"></i>&nbsp;Aceitar</a
                            ><a id="btn-refuse"
                              class="btn btn-sm my-2 btn-outline-secondary" v-if="this.docDebtor.IsApproveByDebtor == null"
                              href="#"
                              @click="approveOrRefuseDocument(0)"><i class="fa fa-close fa-fw"></i>&nbsp;Recusar</a
                            >
                            <a class="btn btn-link btn-sm" href="#"
                              @click="downloadDocument()"><i class="fa fa-download fa-fw"></i
                              >&nbsp;Download&nbsp;</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                <div
                    class="col-lg-12"
                    v-if="!loading && this.docDebtor.IsApproveByDebtor != null"
                  >
                    <p v-if="this.docDebtor.IsApproveByDebtor === 1" style="color: black;">
                      Status: Aceitou proposta Renner
                    </p>
                    <p v-if="this.docDebtor.IsApproveByDebtor === 0" style="color: black;">
                      Status: Recusou proposta Renner
                    </p>
                  </div>
              </div>
        </div>
      </div>
      </div>
      </div>


      <div class="row" style="">
        <div
          class="
            d-inline-flex
            flex-wrap flex-column
            col-md-12 col-acao col-lg-6
          "
        >

      

          <!-- <div class="card" v-if="!loading">
            <div class="card-header" style="">
              <i class="fa fa-fw mr-2 text-secondary fa-edit"></i>Propostas
            </div>
            <div class="card-body" v-if="this.thereIsNoDocs">
              <h6 class="mb-3">Não há nenhuma proposta.</h6>
            </div>
            
            <div class="card-body" v-if="!this.thereIsNoDocs">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h6 class="mb-3">Confira sua proposta</h6>
                  <div class="table-responsive mb-3">
                    <table class="table table-sm" width="100%" cellspacing="0">
                      <thead class=""></thead>
                      <tbody class="text-muted">
                        <tr class="">
                          <td class="align-items-center d-flex">
                            <i class="fa fa-file-text fa-2x mr-2 my-2"></i>
                            <p class="small mb-1">
                              {{ formatDocument(user.DocNumber) }}
                            </p>
                          </td>
                          <td class="small">
                            <a class="btn btn-link btn-sm mt-3 ml-2" href="#"
                             @click="viewDocument()"><i class="fa fa-eye fa-fw"></i
                              >&nbsp;Visualizar&nbsp;</a
                            ><a class="btn btn-link btn-sm ml-2 mt-3" href="#"
                              @click="downloadDocument()"><i class="fa fa-download fa-fw"></i
                              >&nbsp;Download&nbsp;</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-12" v-if="this.docDebtor.IsApproveByDebtor != 1 && this.docDebtor.IsApproveByDebtor != 0">
                  <a class="btn btn-lg btn-secondary" href="#"
                    @click="approveOrRefuseDocument(1)"><i class="fa fa-check fa-fw"></i>&nbsp;Aceitar</a
                  ><a
                    class="btn btn-sm my-2 mx-4 btn-outline-secondary"
                    href="#"
                    @click="approveOrRefuseDocument(0)"><i class="fa fa-close fa-fw"></i>&nbsp;Recusar</a
                  >
                </div>
                <div
                    class="col-lg-12"
                    v-if="!loading && this.docDebtor.IsApproveByDebtor != null"
                  >
                    <p v-if="this.docDebtor.IsApproveByDebtor === 1" style="color: black;">
                      Status: Aceitou proposta Renner
                    </p>
                    <p v-if="this.docDebtor.IsApproveByDebtor === 0" style="color: black;">
                      Status: Recusou proposta Renner
                    </p>
                  </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  formatDocument,
} from "@/utils/helpers";
import { mapState } from "vuex";
import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";

export default {
  name: "Dashboard",
  components: {
    ProgressLoading,
  },
  data() {
    return {
      loadDividas: false,
      loadBoletos: true,
      dividas: null,
      boletos: null,
      links: [],
      loading: false,
      docDebtor: [],
      approveDoc: false,
      refuseDoc: false,
      responseApproveOrRefuse: null,
      thereIsNoDocs: false,
      documentURL: null
    };
  },
  async created() {
    await this.getDocumentDebtor();
    if(this.docDebtor.length > 0) {
      await this.actionDocument(186);
    }
  },
  methods: {
    formatDocument,

    ...mapActions("comprovante", [
      "ActionGetProofPaymentDebtor",
      "ActionDocument",
      "ActionApproveOrRefuseDocument",
    ]),

    async getDocumentDebtor() {
      // debugger;
      this.loading = true;
      try {
        var resp = await this.ActionGetProofPaymentDebtor({
          DebtorID: this.user.DebtorID,
          OwnerID: process.env.VUE_APP_OWNER_ID,
        });

        if (resp.length < 1) {
          this.thereIsNoDocs = true;
        } else {
          this.docDebtor = resp;
        }
        this.loading = false;

      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Erro",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async actionDocument(valueId) {
      this.loading = true;
      try {
        var resp = await this.ActionDocument({
          DebtorProofPaymentID: this.docDebtor.DebtorProofPaymentID,
          DebtorID: this.user.DebtorID,
          DocNumber: this.user.DocNumber,
          ValueID: valueId,
        });

        if(valueId === 187 && !resp.data.error) {
          this.flashMessage.show({
              timeout: 1000,
              status: "success",
              title: "Sucesso",
              message: "Download efetuado com sucesso.",
            });
        }

        this.loading = false;
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async approveOrRefuseDocument(value) {
      this.loading = true;

      try {
        var resp = await this.ActionApproveOrRefuseDocument({
          DebtorProofPaymentID: this.docDebtor.DebtorProofPaymentID,
          AppoveOrRefuseDoc: value,
          DebtorID: this.user.DebtorID,
        });

        var approveOrRefuse = value === 1 ? "Aceite" : "Recusa";

        this.responseApproveOrRefuse = resp.data.results;

        this.flashMessage.show({
          timeout: 1000,
          status: "success",
          title: "Sucesso",
          blockClass: "custom-block-class",
          message: approveOrRefuse + " do documento realizado com sucesso",
        });

        await this.getDocumentDebtor();

        this.loading = false;
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async viewDocument() {
      this.loading = true;

      try {
        if (this.docDebtor.ProofFile != null) {

          const linkSource = `data:${this.docDebtor.MimeFileType};base64,${this.docDebtor.ProofFile}`;
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = linkSource;
          downloadLink.click();
        }

        await this.actionDocument(186);

        this.loading = false;
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async downloadDocument() {
      this.loading = true;

      try {
        if (this.docDebtor.ProofFile != null) {
          const linkSource = `data:${this.docDebtor.MimeFileType};base64,${this.docDebtor.ProofFile}`;
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          const fileName = this.docDebtor.Reference + "." + this.docDebtor.Extension;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }


        await this.actionDocument(187);
        
        
        this.loading = false;
      } catch {
        this.loadBoletos = false;

        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Erro",
          blockClass: "custom-block-class",
          message:
            "Ocorreu um erro ao tentar fazer o download do documento, tente novamente mais tarde",
        });
      }
    },

    b64toBlob(b64Data, contentType) {
      var byteCharacters = atob(b64Data)

      var byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          var slice = byteCharacters.slice(offset, offset + 512),
              byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)

          byteArrays.push(byteArray)
      }

      var blob = new Blob(byteArrays, { type: contentType })
      this.documentURL = URL.createObjectURL(blob) + '#toolbar=0&navpanes=0&scrollbar=0';
    }


        },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  watch: {
    docDebtor: {
      deep: true,
      handler(value) {
        if(value.MimeFileType && value.ProofFile) {
          this.b64toBlob(`${this.docDebtor.ProofFile}`, this.docDebtor.MimeFileType);
        }
        
      }
    }
  }
};
</script>
<style scoped>

@media (min-width: 300px) {
  embed {
    height: 580px !important;
    overflow: auto !important;
  }
}

@media (min-width: 1000px) {
  embed {
    height: 1000px !important;
    overflow: auto;
  }
}

@media (min-width: 800px) {
  #btn-approve {
    max-width: 110px;
  }
}

@media (min-width: 300px) {
  #btn-refuse {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
}




</style>
